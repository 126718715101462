import { isEmpty, isObject } from './object';
import { find } from './array';
import { isStr } from '../common/validators';
import { isNumber } from './number';
import { SortSource, Sort } from '../typing/enums';
import { sortByKey } from './sort';

export const roundToBreakpoint = (value, breakpoint) => {
  return Math.ceil(value / breakpoint) * breakpoint;
};

export const getAssetSource = source => (Array.isArray(source.assets) ? source.assets[0] : source);

export const getAdjustedDims = (width, height, breakpoint = 100) => {
  const nextWidth = roundToBreakpoint(width, breakpoint);
  const nextHeight = Math.floor(nextWidth / (width / height));

  return {
    width: nextWidth,
    height: nextHeight
  };
};

export const checkSameRatio = (w1, h1, w2, h2) =>
  Math.floor((w1 / h1) * 100) === Math.floor((w2 / h2) * 100);

export const getTransformation = (resourceTransformation = {}, config) =>
  !isEmpty(resourceTransformation) ? resourceTransformation : config.selectTransformation();

export const getAssetMetadataValue = (source, mediaId) => {
  const metadataItem = mediaId && find(source.metadata, i => i.external_id === mediaId);

  return metadataItem && (isStr(metadataItem.value) || isNumber(metadataItem.value))
    ? metadataItem.value
    : undefined;
};

export const getAssetContextualValue = (source, mediaId = 'alt') => {
  const custom = source.context && source.context.custom;

  return custom && custom[mediaId];
};

const stringToNumber = value => {
  const number = +value;

  return isNumber(number) ? number : value;
};

const getSortedAssetsByPublicId = (assets, direction) => {
  return assets.sort(sortByKey(`${direction === Sort.DESC ? '-' : ''}publicId`));
};

const getSortedStructuredMetadataAssets = (assets, sortProps) => {
  const direction = sortProps.direction === Sort.DESC ? -1 : 1;
  const isMetadata = sortProps.source === SortSource.METADATA;

  return assets.sort((a, b) => {
    const assetA = getAssetSource(a);
    const assetB = getAssetSource(b);

    const valueA = isMetadata
      ? getAssetMetadataValue(assetA, sortProps.id)
      : getAssetContextualValue(assetA, sortProps.id);

    const valueB = isMetadata
      ? getAssetMetadataValue(assetB, sortProps.id)
      : getAssetContextualValue(assetB, sortProps.id);

    if (valueA === valueB) {
      return 0;
    }

    if (valueA === undefined) {
      return direction;
    }

    if (valueB === undefined) {
      return -1 * direction;
    }

    // "20 > "3" is false , try to convert it to numbers
    return stringToNumber(valueA) > stringToNumber(valueB) ? direction : -1 * direction;
  });
};

export const getSortedAssets = (assets, sortProps, sort) => {
  if (isObject(sortProps)) {
    switch (sortProps.source) {
      case SortSource.METADATA:
      case SortSource.CONTEXTUAL:
        return getSortedStructuredMetadataAssets(assets, sortProps);
      case SortSource.PUBLIC_ID:
        return getSortedAssetsByPublicId(assets, sortProps.direction);
      default:
        return assets;
    }
  }

  return sort === Sort.NONE ? assets : getSortedAssetsByPublicId(assets, sort);
};

export const getIndexedAssets = assets => {
  return assets.map((item, assetIndex) => ({ assetIndex, ...item }));
};
