const observe = (selector, config, cb) => {
  const elements = typeof selector === 'string' ? document.querySelectorAll(selector) : selector;

  const observer = new IntersectionObserver(entries => {
    const crossed = entries.filter(entry => entry.isIntersecting);
    if (crossed.length) {
      cb(crossed);
    }
  }, config.observer);

  const targets = Array.prototype.slice.apply(elements);
  targets.forEach(target => {
    observer.observe(target);
  });

  return {
    isIntersecting: elm => {
      const match = observer.takeRecords().find(r => r.target === elm);

      return match ? match.isIntersecting : false;
    },
    destroy: () => {
      targets.forEach(target => {
        observer.unobserve(target);
      });
      observer.disconnect();
    }
  };
};

export default observe;
