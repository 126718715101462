export const getDimensions = (width, aspectRatio) => {
  if (aspectRatio === 'square') {
    return {
      width: Math.round(width),
      height: Math.round(width)
    };
  }
  const ar = aspectRatio.split(':');
  const height = width / (Number(ar[0]) / Number(ar[1]));

  return {
    width: Math.round(width),
    height: Math.round(height)
  };
};
