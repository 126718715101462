import { h } from 'preact';
import SvgIcon from '../../SvgIcon/SvgIcon';
import * as ICONS from '../../../assets/icons';
import { BaseButtonWrapper } from './BaseButton.styled';

const BaseButton = props => {
  const icon = ICONS[props.icon || ''];
  const iconSize = props.size - (props.size / 4) * 2 - (props.borderWidth || 0) * 2;

  return (
    <BaseButtonWrapper
      {...props}
      onClick={props.onClick}
      className={props.className}
      size={props.size}
    >
      {icon ? <SvgIcon color={props.iconColor} path={icon} size={iconSize} /> : null}
    </BaseButtonWrapper>
  );
};

export default BaseButton;
