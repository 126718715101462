import { Component, h } from 'preact';
import { css } from 'react-emotion';
import { withContext } from '../../common/context';
import Loading from '../Loading/Loading';
import { FlexCenterStretched } from '../../utils/emotion';
import helpers from '../../utils/imageHelpers';

class Placeholder extends Component {
  componentDidMount() {
    if (this.props.publicId && this.props.context.config.selectPlaceholderImage()) {
      this.loadPlaceholder();
    }
  }

  async loadPlaceholder() {
    const asset = await helpers.loadLowResImage(this.props);
    this.setState({ url: asset.url });
  }

  render(props, state) {
    const { config } = props.context;

    return (
      <FlexCenterStretched relative>
        {config.selectPlaceholderImage() ? (
          <img
            src={state.url}
            width={props.width}
            height={props.height}
            className={css`
              height: 100% !important;
              width: 100% !important;
              object-fit: contain;
              filter: blur(20px);
            `}
            alt=""
          />
        ) : null}
        <FlexCenterStretched absolute>
          <Loading
            width={props.width}
            height={props.height}
            color={config.selectLoaderPropsColor() || config.selectThemePropsPrimary()}
            size={config.selectLoaderPropsSize()}
            opacity={config.selectLoaderPropsOpacity()}
            style={config.selectLoaderPropsStyle()}
            url={config.selectLoaderPropsUrl()}
          />
        </FlexCenterStretched>
      </FlexCenterStretched>
    );
  }
}

export default withContext(Placeholder);
