import { h, Component } from 'preact';
import observeIntersection from '../../utils/intersctionService';
import scrollTo from '../../utils/scrollToHelper';
import { ScrollListWrapper } from './ScrollListItems.styled';

class ScrollListItems extends Component {
  itemsRefs = [];

  windowInnerHeight = 0;

  itemsObserver = null;

  viewableItems = 0;

  componentDidMount() {
    this.windowInnerHeight = window.innerHeight;
    this.observeItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.itemHeight !== this.props.itemHeight ||
      this.windowInnerHeight !== window.innerHeight ||
      prevProps.items !== this.props.items
    ) {
      if (prevProps.items !== this.props.items) {
        this.itemsRefs.splice(this.props.items.length);
      }
      this.windowInnerHeight = window.innerHeight;
      this.observeItems();
    }
    const scrollToIndex = this.props.scrollToIndex;
    if (this.itemsObserver && scrollToIndex !== null && scrollToIndex !== prevProps.scrollToIndex) {
      const elm = this.itemsRefs[scrollToIndex];
      const isIntersecting = this.itemsObserver.isIntersecting(elm);
      if (!isIntersecting) {
        const offset = -this.props.topOffset;
        scrollTo(elm, { offset });
      }
    }
  }

  componentWillUnmount() {
    if (this.itemsObserver) {
      this.itemsObserver.destroy();
    }
  }

  observeItems = () => {
    if (this.itemsObserver) {
      this.itemsObserver.destroy();
    }
    if (this.itemsRefs.length) {
      this.itemsObserver = observeIntersection(
        this.itemsRefs,
        {
          observer: {
            threshold: this.props.intersectThreshold,
            rootMargin: this.props.intersectionMargin
          }
        },
        observed => {
          if (observed.length) {
            const intersectedElm = observed[0].target;
            const index = intersectedElm.dataset['index'];
            this.props.setSelected(Number(index));
          }
        }
      );
    }
  };

  setItemRef = (elm, index) => {
    this.itemsRefs[index] = elm;
  };

  updateViewableItems = () =>
    (this.viewableItems =
      (Math.ceil(this.windowInnerHeight / this.props.itemHeight) || 1) * this.props.columns);

  render() {
    this.updateViewableItems();

    return (
      <ScrollListWrapper>
        {this.props.items.map((item, index) =>
          this.props.renderItem(item, index, this.viewableItems, this.setItemRef)
        )}
      </ScrollListWrapper>
    );
  }
}

export default ScrollListItems;
