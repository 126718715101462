/* eslint-disable no-undef */
const _VERSION = BUILD_VERSION;
const _ENV = ENV;
const _THREE_DRACO_URL = 'https://www.gstatic.com/draco/v1/decoders/';
const _ANALYTICS_URL = 'https://analytics-api-s.cloudinary.com';
const _USE_ANALYTICS = USE_ANALYTICS;

export { _VERSION as VERSION };

export { _ENV as ENV };

export { _THREE_DRACO_URL as THREE_DRACO_URL };

export { _ANALYTICS_URL as ANALYTICS_URL };

export { _USE_ANALYTICS as USE_ANALYTICS };
