import { h } from 'preact';
import { cx } from 'react-emotion';
import SvgIcon from '../SvgIcon/SvgIcon';
import * as ICONS from '../../assets/icons';
import { MediaSymbolShape } from '../../typing/enums';
import { MEDIA_ICON_DEFAULTS, MEDIA_ICON_WITH_BG_DEFAULTS } from '../../config/defaults';
import {
  getBackgroundColorCls,
  getShadowCls,
  getStyleCls,
  isBackgroundColor,
  MediaSymbolWrapper,
  getIcon
} from './MediaSymbol.styled';

const getPropsDefaults = propsIn =>
  propsIn.shape === MediaSymbolShape.NONE
    ? MEDIA_ICON_DEFAULTS
    : { ...MEDIA_ICON_DEFAULTS, ...MEDIA_ICON_WITH_BG_DEFAULTS };

const MediaSymbol = propsIn => {
  const props = { ...getPropsDefaults(propsIn), ...propsIn };
  const bgCls = isBackgroundColor(props.shape, props.type)
    ? getBackgroundColorCls(props.color || '', props.opacity || 0)
    : '';
  const styleCls = getStyleCls(props);
  const shadowCls = props.iconShadow ? getShadowCls() : '';

  return (
    <MediaSymbolWrapper
      {...props}
      className={cx(bgCls, styleCls, props.className)}
      data-test="media-symbol-shape"
    >
      <SvgIcon
        className={shadowCls}
        color={props.iconColor}
        path={props.icon ? ICONS[props.icon] : getIcon(props.type)}
      />
    </MediaSymbolWrapper>
  );
};

export default MediaSymbol;
