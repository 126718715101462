import { cx } from 'emotion';
import Button from '../Button/Button';
import { h } from 'preact';
import { baseCls, posCls } from './NavButton.styled';

const NavButton = props => {
  const icon = props.direction === 'left' ? 'arrowLeft' : 'arrowRight';

  return (
    <Button
      className={cx(baseCls(props), posCls(props))}
      {...props}
      icon={props.icon || icon}
      onClick={props.onClick}
      data-test={`nav-${props.direction === 'left' ? 'prev' : 'next'}`}
    />
  );
};

export default NavButton;
