const findElementOffset = elm => {
  let offset = 0;
  let offsetParent = elm;
  while (offsetParent) {
    offset += offsetParent.offsetTop;
    offsetParent = offsetParent.offsetParent;
  }

  return offset;
};
const scrollTo = (selector, options = {}) => {
  const element = typeof selector === 'string' ? document.querySelector(selector) : selector;
  if (element) {
    const elmOffset = findElementOffset(element);
    window.scrollTo({
      top: elmOffset + (options.offset ? options.offset : 0),
      behavior: 'smooth'
    });
  }
};

export default scrollTo;
