import { h } from 'preact';
import SvgIcon from '../SvgIcon/SvgIcon';
import { info } from '../../assets/icons';
import { NotFoundWrapper } from './NotFound.styled';

const NotFound = ({ bgColor = 'transparent', msg }) => {
  return (
    <NotFoundWrapper bgColor={bgColor}>
      <SvgIcon color={`#F9A825`} path={info} size={60} />
      <div data-test="not-found-message" style={{ margin: '20px 0' }}>
        {msg}
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
