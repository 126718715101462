export let KeyboardKey;
(function (KeyboardKey) {
  KeyboardKey['Down'] = 'Down';
  KeyboardKey['Enter'] = 'Enter';
  KeyboardKey['Spacebar'] = 'Spacebar';
  KeyboardKey['ArrowDown'] = 'ArrowDown';
  KeyboardKey['Right'] = 'Right';
  KeyboardKey['ArrowRight'] = 'ArrowRight';
  KeyboardKey['Up'] = 'Up';
  KeyboardKey['ArrowUp'] = 'ArrowUp';
  KeyboardKey['Left'] = 'Left';
  KeyboardKey['ArrowLeft'] = 'ArrowLeft';
})(KeyboardKey || (KeyboardKey = {}));
