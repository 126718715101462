import styled from 'react-emotion';

export const NotFoundWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgColor};
  text-align: center;
`;
