import styled from 'react-emotion';

export const ScrollViewerWrapper = styled('div')`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  > img {
    max-width: 100%;
    width: 100%;
  }

  border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
  border-radius: ${props => props.radius}px;
`;
