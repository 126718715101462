import { h } from 'preact';
import { Axis } from '../../typing/enums';
import { INDICATOR_DEFAULTS } from '../../config/defaults';
import { isOdd } from '../../utils/number';
import { withContext } from '../../common/context';
import { Events } from '../../utils/events';
import {
  getItemSize,
  getItemsTotalSize,
  IndicatorWrap,
  getSelectedSize,
  IndicatorInnerWrap,
  IndicatorItem
} from './Indicator.styled';

const calcMaxItems = props => {
  const totalItemsWidth = getItemsTotalSize(props);
  const itemSize = getItemSize(props);
  const totalWrapSize = (props.axis === Axis.VERTICAL ? props.wrapHeight : props.wrapWidth) - 70;
  let total;
  if (totalItemsWidth > totalWrapSize) {
    total = Math.floor(totalWrapSize / itemSize);
  } else {
    total = isOdd(props.amount) ? props.amount : props.amount + 1;
  }

  return isOdd(total) ? total : total - 1;
};

const Indicators = propsIn => {
  const props = { ...INDICATOR_DEFAULTS, ...propsIn };
  const {
    onItemSelect,
    context: { events, config }
  } = props;
  const max = calcMaxItems(props);
  const onIndicatorItemClick = index => {
    onItemSelect(index);
    const mediaAssets = config.selectMediaAssets();
    events(Events.INDICATOR_CLICK, mediaAssets[index].publicId);
  };

  return (
    <IndicatorWrap {...props} data-test="indicators-wrap" max={max}>
      <IndicatorInnerWrap {...props} max={max}>
        {Array.from(Array(props.amount)).map((value, index) => (
          <IndicatorItem
            key={index}
            onClick={() => onIndicatorItemClick(index)}
            color={props.color}
            size={index === props.selected ? getSelectedSize(props.size) : props.size}
            shape={props.shape}
            spacing={props.spacing}
            selectedColor={props.selectedColor}
            selected={index === props.selected}
            data-test={`indicator-item-${index}`}
          />
        ))}
      </IndicatorInnerWrap>
    </IndicatorWrap>
  );
};

export default withContext(Indicators);
