import styled from 'react-emotion';
import BaseButton from '../BaseButton/BaseButton';
import { getRgbaColor } from '../../../utils/color';

export const TransparentButtonWrapper = styled(BaseButton)`
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: ${props => getRgbaColor(props.iconColor)}};
    stroke: ${props => getRgbaColor(props.color, 0.7)}};
    stroke-width: 0.5px;
  }
`;
