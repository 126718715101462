import styled from 'react-emotion';
import { Axis, Navigation } from '../../typing/enums';

export const StyledViewerWrapper = styled('div')`
  position: relative;
  ${props => (props.axis === Axis.VERTICAL ? 'flex: 1 1 100%;width: 1px;' : '')};

  ${props =>
    props.navigation === Navigation.MOUSE_OVER
      ? '&:hover button:not([disabled]) { opacity: 1 }'
      : ''};
`;
