import styled from 'react-emotion';

export const AssetWrapper = styled('button')`
  // Button reset & focus styles
  font-family: inherit;
  font-size: 100%;
  padding: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  cursor: inherit;

  &.assetWrapper {
    // Sizes & margins
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;

    margin-right: ${({ addSideMargin, spacing }) => (addSideMargin && spacing ? spacing : 0)}px;

    margin-bottom: ${({ spacing }) => spacing || 0}px;

    &.focus-visible:focus {
      .thumbnails-wrap & {
        border: 1px solid rgba(0, 92, 228, 0.5);
      }
      .viewer-wrap & {
        z-index: 1;
        outline: 1px solid rgba(0, 92, 228, 0.5);
      }
    }
  }
`;
