import { MediaSymbolTypes } from '../typing/enums';
import { getAdjustedDims } from './assetHelpers';

const getImageUrl = (props, width, height, transformation, analytics) => {
  const {
    mediaType,
    publicId,
    context: { cloudinary }
  } = props;

  switch (mediaType) {
    case MediaSymbolTypes.VIDEO:
      return cloudinary.getVideoThumbnailUrl(publicId, width, height, transformation, analytics);
    case MediaSymbolTypes.THREE:
      return cloudinary.getThreeThumbnailUrl(publicId, width, height, transformation, analytics);
    default:
      return cloudinary.getImageUrl(publicId, width, height, transformation, analytics);
  }
};

const loadLowResImage = async props => {
  const {
    publicId,
    transformation,
    breakpoint,
    fetchPriority,
    context: { cacher, config }
  } = props;

  const thumbWidth = config.selectThumbnailPropsWidth();
  const thumbHeight = config.selectThumbnailPropsHeight();
  const adjDims = getAdjustedDims(thumbWidth, thumbHeight, breakpoint);
  const url = getImageUrl(props, adjDims.width, adjDims.height, transformation);

  try {
    return await cacher(publicId, url, thumbWidth, thumbHeight, transformation, fetchPriority);
  } catch (error) {
    throw new Error('Failed to cache low resolution image');
  }
};

const loadHighResImage = async props => {
  const {
    publicId,
    width,
    height,
    transformation,
    breakpoint,
    fetchPriority,
    context: { cacher }
  } = props;
  const adjDims = getAdjustedDims(width, height, breakpoint);
  const url = getImageUrl(props, adjDims.width, adjDims.height, transformation);

  try {
    return await cacher(
      publicId,
      url,
      adjDims.width,
      adjDims.height,
      transformation,
      fetchPriority
    );
  } catch (error) {
    throw new Error('Failed to cache high resolution image');
  }
};

const loadZoomImage = async props => {
  const {
    publicId,
    width,
    height,
    transformation,
    breakpoint,
    context: { cacher, config }
  } = props;
  const adjDims = getAdjustedDims(width, height, breakpoint);
  const zoomLevel = config.selectZoomPropsLevel();
  const zoomWidth = adjDims.width * zoomLevel;
  const zoomHeight = adjDims.height * zoomLevel;

  const url = getImageUrl(props, zoomWidth, zoomHeight, transformation, { pgwact: 1 });

  try {
    return await cacher(publicId, url, zoomWidth, zoomHeight, transformation);
  } catch (error) {
    throw new Error('Failed to cache zoom image');
  }
};

export default {
  loadLowResImage,
  loadHighResImage,
  loadZoomImage
};
