import { snakeToCamel } from './string';

export const getObjectByPrefix = (prefix, obj) => {
  return Object.keys(obj)
    .filter(key => key.startsWith(prefix))
    .reduce((newObj, key) => {
      let newKey = key.replace(prefix, '');
      newKey = newKey.replace(newKey.charAt(0), newKey.charAt(0).toLowerCase());
      newObj[newKey] = obj[key];

      return newObj;
    }, {});
};

export const keysFromSnakeToCamel = obj => {
  return {
    ...Object.keys(obj).reduce((acc, key) => {
      acc[snakeToCamel(key)] = obj[key];

      return acc;
    }, {})
  };
};

export const isObject = value => (value && value.constructor === Object) || false;

export const isEmpty = value => {
  return isObject(value) ? Object.keys(value).length === 0 : false;
};

// not supporting arrays  - not needed at the momemnt
export const objectToQuerystring = (obj = {}) =>
  Object.keys(obj).reduce(function (str, key, i) {
    let delimiter;
    let val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);

    return [str, delimiter, key, '=', val].join('');
  }, '');

export const isNil = value => [null, undefined, ''].includes(value);

export const pick = (obj, keys) => {
  return keys.reduce((acc, key) => {
    const value = obj[key];

    if (!isNil(value)) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const omit = (obj, keys) => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (!keys.includes(key)) {
      acc[key] = value;
    }

    return acc;
  }, {});
};
