import { h } from 'preact';
import BaseButton from './BaseButton/BaseButton';
import RectangleButton from './RectangleButton/RectangleButton';
import RoundButton from './RoundButton/RoundButton';
import RadiusButton from './RadiusButton/RadiusButton';
import TransparentButton from './TransparentButton/TransparentButton';
import { ButtonShape } from '../../typing/enums';
import { BUTTON_DEFAULTS } from '../../config/defaults';

const Button = propsIn => {
  const props = { ...BUTTON_DEFAULTS, ...propsIn };
  const shape = props.shape;

  return shape === ButtonShape.ROUND ? (
    <RoundButton {...props} />
  ) : shape === ButtonShape.SQUARE ? (
    <BaseButton {...props} />
  ) : shape === ButtonShape.RADIUS ? (
    <RadiusButton {...props} />
  ) : shape === ButtonShape.RECTANGLE ? (
    <RectangleButton {...props} />
  ) : shape === ButtonShape.NONE ? (
    <TransparentButton {...props} />
  ) : (
    <BaseButton {...props} />
  );
};

export default Button;
