import { isMobile } from 'mobile-device-detect';
import keys from '../utils/keys';
import { Axis, CarouselLocation, CarouselStyle, DisplayMode, PreloadTypes } from '../typing/enums';
import { mapKeysToProps } from './props';
import { getObjectByPrefix, isObject } from '../utils/object';
import { toCamelCase } from '../utils/string';
import { getWidth } from '../utils/dom';

export const selectConfig = config => config;

export const selectThumbnailsCarouselProps = config => ({
  navigationIconColor: config.themeProps.onPrimary,
  navigationColor: config.themeProps.primary,
  ...mapKeysToProps(keys('CarouselProps'), config.thumbnailProps),
  axis: selectAxis(config),
  itemWidth: config.thumbnailProps.width,
  itemHeight: config.thumbnailProps.height,
  navigation: true
});

export const selectThumbnailSelectedProps = config => {
  const props = getObjectByPrefix('selected', config.thumbnailProps);
  const themeProps = {
    gradientStart: config.themeProps.primary,
    gradientEnd: config.themeProps.primary,
    borderColor: config.themeProps.active
  };

  return mapKeysToProps(keys('SelectedItemProps'), { ...themeProps, ...props });
};

export const selectMediaSymbolProps = config => {
  const propsIn = getObjectByPrefix('mediaSymbol', config.thumbnailProps);
  const props = Object.keys(propsIn).reduce((acc, propName) => {
    acc[toCamelCase(propName.replace('bg', ''))] = propsIn[propName];

    return acc;
  }, {});
  const themeProps = {
    iconColor: config.themeProps.onPrimary,
    color: config.themeProps.primary
  };

  return mapKeysToProps(keys('MediaSymbolProps'), { ...themeProps, ...props });
};

export const selectIndicatorProps = config => {
  const themeProps = {
    color: config.themeProps.onPrimary,
    selectedColor: config.themeProps.active
  };

  return mapKeysToProps(keys('IndicatorProps'), {
    ...themeProps,
    ...config.indicatorProps
  });
};

export const selectZoomPopupProps = config => {
  const themeProps = {
    buttonColor: config.themeProps.onPrimary,
    buttonIconColor: config.themeProps.primary
  };
  const zoomProps = {
    level: config.zoomProps.level,
    steps: config.zoomProps.steps,
    stepLimit: config.zoomProps.stepLimit
  };

  return mapKeysToProps(keys('ZoomPopupProps'), {
    ...themeProps,
    ...zoomProps,
    ...config.zoomPopupProps
  });
};

export const selectNavigationButtonProps = config =>
  mapKeysToProps(keys('NavigationButtonProps'), {
    iconColor: config.themeProps.onPrimary,
    color: config.themeProps.primary,
    ...config.navigationButtonProps
  });

export const selectAxis = config =>
  selectCarouselLocation(config) === CarouselLocation.LEFT ||
  selectCarouselLocation(config) === CarouselLocation.RIGHT
    ? Axis.VERTICAL
    : Axis.HORIZONTAL;

export const selectThumbnailWidth = config => config.thumbnailProps.width || 0;

export const selectThumbnailHeight = config => config.thumbnailProps.height || 0;

export const selectViewerWidth = config => {
  const columns =
    (selectDisplayPropsMode(config) === DisplayMode.EXPANDED &&
      config.displayProps &&
      config.displayProps.columns) ||
    1;
  const container = selectContainer(config);
  const isIndicators = config.carouselStyle === CarouselStyle.INDICATORS;
  const isNone = config.carouselStyle === CarouselStyle.NONE;
  const carouselWidth =
    (isIndicators
      ? // Active indicator width (normal * 1.4) + margin left + margin right
        Math.round(config.indicatorProps.size * 1.4) + 2 * config.indicatorProps.spacing
      : isNone
        ? 0
        : config.thumbnailProps.width) || 0;
  const axis = selectAxis(config);
  const containerWidth = container ? getWidth(container) : 0;
  const carouselContainerWidth =
    axis === Axis.VERTICAL && !config.thumbnailContainer
      ? (columns === 1 && carouselWidth) || 0
      : 0;
  const carouselOffsetWidth = axis === Axis.VERTICAL ? config.carouselOffset || 0 : 0;

  return container ? containerWidth - carouselContainerWidth - carouselOffsetWidth : 0;
};

export const selectContainer = config => {
  return config.container instanceof HTMLElement
    ? config.container
    : document.querySelector(config.container);
};

export const selectPreloadImage = config => {
  return config.preload ? config.preload.indexOf(PreloadTypes.IMAGE) > -1 : false;
};

export const selectPreloadVideo = config => {
  return config.preload ? config.preload.indexOf(PreloadTypes.VIDEO) > -1 : false;
};

export const selectPreloadSpin = config => {
  return config.preload ? config.preload.indexOf(PreloadTypes.SPIN) > -1 : false;
};

export const selectPreloadThree = config => {
  return config.preload ? config.preload.indexOf(PreloadTypes.THREE) > -1 : false;
};

export const selectSticky = config => {
  const carouselStyle =
    config.carouselStyle === CarouselStyle.THUMBNAILS ? 'thumbnailProps' : 'indicatorProps';

  return (config[carouselStyle] && config[carouselStyle].sticky) || false;
};

export const selectCarouselLocation = config => {
  let location = config.carouselLocation;
  // In expanded mode and desktop - force thumbnails to either left or right
  if (config.displayProps && config.displayProps.mode === DisplayMode.EXPANDED && !isMobile) {
    location =
      config.carouselLocation !== CarouselLocation.RIGHT
        ? CarouselLocation.LEFT
        : CarouselLocation.RIGHT;
  }

  return location;
};

// For now, we support only CLASSIC display mode for mobile
export const selectDisplayPropsMode = config => {
  return (!isMobile && config.displayProps && config.displayProps.mode) || DisplayMode.CLASSIC;
};

export const selectQueryParam = config => {
  return config.queryParam ? { _i: config.queryParam } : {};
};

export const selectors = {
  selectConfig,
  selectAxis,
  selectViewerWidth,
  selectThumbnailWidth,
  selectThumbnailHeight,
  selectContainer,
  selectThumbnailsCarouselProps,
  selectThumbnailSelectedProps,
  selectMediaSymbolProps,
  selectIndicatorProps,
  selectNavigationButtonProps,
  selectPreloadImage,
  selectPreloadVideo,
  selectPreloadSpin,
  selectPreloadThree,
  selectZoomPopupProps,
  selectSticky,
  selectCarouselLocation,
  selectDisplayPropsMode,
  selectQueryParam
};

export const bindSelectorsToConfig = (config, cloudinary) => {
  const autoSelectors = keys('ConfigProps').reduce((acc, key) => {
    const selectorName = toCamelCase(`select ${key}`);
    if (!/children/.test(key)) {
      if (isObject(config[key]) && ~key.toLowerCase().indexOf('props')) {
        Object.keys(config[key]).forEach(k => {
          acc[toCamelCase(`select ${key} ${k}`)] = () => config[key][k];
        });
      }
      acc[selectorName] = () => config[key];
    }

    return acc;
  }, {});
  const customSelectors = Object.keys(selectors).reduce((acc, selector) => {
    acc[selector] = selectors[selector].bind(null, config, cloudinary);

    return acc;
  }, {});

  return {
    __orig: config,
    ...selectors,
    ...autoSelectors,
    ...customSelectors
  };
};
