import styled, { css, cx } from 'react-emotion';
import { MediaSymbolShape, MediaSymbolTypes } from '../../typing/enums';
import * as ICONS from '../../assets/icons';
import { getRgbaColor } from '../../utils/color';

export const MediaSymbolWrapper = styled('div')`
  display: inline-flex;
`;

const typeToIconMap = {
  [MediaSymbolTypes.SPIN]: ICONS.spin,
  [MediaSymbolTypes.VIDEO]: ICONS.play,
  [MediaSymbolTypes.THREE]: ICONS.i3D,
  [MediaSymbolTypes.IMAGE]: ICONS.image
};

export const getIcon = type => typeToIconMap[type] || '';

export const getBackgroundColorCls = (color, opacity) => css`
  background-color: ${getRgbaColor(color, opacity)};
`;

export const getShadowCls = () => css`
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.7));
`;

export const isBackgroundColor = style => style !== MediaSymbolShape.NONE;

export const getStyleCls = props => {
  const style = props.shape;
  const size = props.size || 0;
  const roundCls = css`
    border-radius: 100%;
    padding: ${size / 5}px;
  `;
  const squareCls = css`
    padding: ${size / 5}px;
  `;
  const radiusCls = css`
    border-radius: ${size / 5}px;
    padding: ${size / 5}px;
  `;

  return cx(
    css`
      align-items: center;
      justify-content: center;
      width: ${props.size}px;
      height: ${props.size}px;
    `,
    style === MediaSymbolShape.ROUND
      ? roundCls
      : style === MediaSymbolShape.SQUARE
        ? squareCls
        : style === MediaSymbolShape.RADIUS
          ? radiusCls
          : ''
  );
};
