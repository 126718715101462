export const isSmallerThenLoaded = (assets, width) =>
  assets.filter(asset => width <= asset.width).length > 0;

export const getLargestImageLoaded = assets => assets.sort((a, b) => b.width - a.width)[0];

export const getAspectRatio = (width, height) => Math.floor((width / height) * 100);

export const getAssetsWithSameAspectRatio = (
  chachedAssets,
  publicId,
  width,
  height,
  transformation
) => {
  if (!chachedAssets[publicId]) {
    return [];
  }

  return chachedAssets[publicId].filter(asset => {
    return (
      asset.transformation === transformation &&
      getAspectRatio(asset.width, asset.height) === getAspectRatio(width, height)
    );
  });
};

export const cacher = () => {
  let cachedAssets = {};

  return (publicId, url, width, height, transformation, fetchPriority) =>
    new Promise((resolve, reject) => {
      const trans = JSON.stringify(transformation);
      const assets = getAssetsWithSameAspectRatio(cachedAssets, publicId, width, height, trans);
      if (assets.length) {
        if (isSmallerThenLoaded(assets, width)) {
          resolve(getLargestImageLoaded(assets));

          return;
        }
      }
      const img = new Image();
      if (fetchPriority) {
        img.fetchPriority = fetchPriority;
      }
      img.src = url;
      img.onload = () => {
        if (!cachedAssets[publicId]) {
          cachedAssets[publicId] = [];
        }
        const newAsset = {
          domNode: img,
          width,
          height,
          url,
          transformation: trans
        };
        cachedAssets[publicId].push(newAsset);
        resolve(newAsset);
      };
      img.onerror = () => {
        reject(img);
      };
    });
};
