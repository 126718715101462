import { h } from 'preact';
import { memo, useState } from 'preact/compat';
import { withContext } from '../../common/context';
import { AssetType, FETCH_PRIORITY, MediaSymbolTypes } from '../../typing/enums';
import { AssetWrapper } from './Asset.styled';
import { KeyboardKey } from '../../typing/keyboardKey';
import { FlexCenterStretched } from '../../utils/emotion';
import Placeholder from '../Placeholder/Placeholder';
import Error from '../Error/Error';

import { LazyComponent } from '../LazyComponent/LazyComponent';

const Image = LazyComponent(() => import(/* webpackChunkName: "image" */ '../Image/Image'));
const Video = LazyComponent(() => import(/* webpackChunkName: "video" */ '../Video/Video'));
const Spin = LazyComponent(() => import(/* webpackChunkName: "spin" */ '../Spin/Spin'));
const Three = LazyComponent(() => import(/* webpackChunkName: "three" */ '../Three/Three'));

const Asset = props => {
  const { config } = props.context;
  const [isRendered, setIsRendered] = useState(false);

  const addSideMargin =
    typeof props.index !== 'undefined' && props.columns
      ? !!((props.index + 1) % props.columns)
      : false;

  const selectAsset = () => {
    if (props.onItemClick) {
      props.onItemClick(props.index);
    }
  };

  const shouldAssetRender = () => {
    if (!isRendered && !props.inView) return false;

    // else, mark as rendered, keep it in DOM
    setIsRendered(true);

    return true;
  };

  const getPublicID = () => {
    // If spin - get publicId from the first asset
    if (props.mediaType === MediaSymbolTypes.SPIN && props.assets) {
      return props.assets[0]?.publicId;
    }

    // Else
    return props.publicId;
  };

  const handleMouseClick = e => {
    // e.detail confirms this is truely a mouse click
    if (e.detail > 0) {
      selectAsset();
    }
  };

  const handleKeyPress = e => {
    if (e.repeat) {
      return;
    }
    if (e.key === KeyboardKey.Enter || e.key === KeyboardKey.Spacebar || e.key === ' ') {
      if (props.setZoom && props.isSelected !== false) {
        // Open clicked item zoom, or close it if already opened.
        const zoomState = props.zoomState ? -1 : props.index;
        props.setZoom(zoomState);
      } else if (props.onItemClick) {
        // Similar to a `click` event
        selectAsset();
      }
    }
    e.preventDefault(); // Otherwise it will also trigger a click event
  };

  const handleKeyUp = e => {
    if (e.key === KeyboardKey.Spacebar || e.key === ' ') {
      handleKeyPress(e);
    }
  };

  const handleKeyDown = e => {
    if (e.key === KeyboardKey.Enter) {
      handleKeyPress(e);
    }
  };

  const renderError = () => (
    <FlexCenterStretched absolute>
      <Error width={props.width} />
    </FlexCenterStretched>
  );

  const renderLoading = publicId => {
    return (
      <FlexCenterStretched data-test={`${props.mediaType}-loading-wrap`}>
        <Placeholder
          publicId={publicId || props.publicId}
          width={props.width}
          height={props.height}
          mediaType={props.mediaType}
          transformation={props.transformation}
        />
      </FlexCenterStretched>
    );
  };

  const renderAsset = () => {
    const publicId = getPublicID();
    const selectStartIndex = config.selectStartIndex();

    if (!publicId) return <Error width={props.width} />;

    if (
      props.mediaType === MediaSymbolTypes.IMAGE ||
      (props.mediaType === MediaSymbolTypes.VIDEO && props.resourceType === AssetType.IMAGE) ||
      (props.mediaType === MediaSymbolTypes.SPIN && props.resourceType) ||
      (props.mediaType === MediaSymbolTypes.THREE && props.resourceType === AssetType.IMAGE)
    ) {
      if (!shouldAssetRender() && !config.selectPreloadImage()) return renderLoading();

      return (
        <Image
          publicId={publicId}
          mediaType={props.mediaType}
          resourceType="image"
          transformation={props.transformation}
          index={props.index}
          active={props.inView ? true : config.selectPreloadImage()}
          width={props.width}
          height={props.height}
          alt={props.alt}
          breakpoint={props.useBreakpoint ? config.selectImageBreakpoint() : undefined}
          zoom={props.zoom && props.inView}
          zoomState={props.zoomState}
          setZoom={props.setZoom}
          inPopup={props.inPopup}
          fetchPriority={
            props.fetchPriority ||
            (props.index === selectStartIndex ? FETCH_PRIORITY.HIGH : FETCH_PRIORITY.AUTO)
          }
        />
      );
    } else if (props.mediaType === MediaSymbolTypes.VIDEO) {
      if (!shouldAssetRender() && !config.selectPreloadVideo()) return renderLoading();

      // need to mount video again with key since video has some react limitation while changing
      const key = `${publicId}.${JSON.stringify(props.transformation)}.${Math.round(
        (props.width / props.height) * 100
      )}`;

      return (
        <Video
          publicId={publicId}
          isFirst={props.index === 0}
          width={props.width}
          height={props.height}
          active={props.inView}
          preload={config.selectPreloadVideo() || props.preloadIntersection}
          transformation={props.transformation}
          key={key}
          ariaLabel={props.alt}
          videoPlayerSource={props.videoPlayerSource}
          assetTransformation={props.assetTransformation}
          breakpoint={props.useBreakpoint ? config.selectVideoBreakpoint() : undefined}
        />
      );
    } else if (props.mediaType === MediaSymbolTypes.SPIN) {
      if (!shouldAssetRender() && !config.selectPreloadSpin()) return renderLoading(publicId);

      return (
        <Spin
          width={props.width}
          height={props.height}
          assets={props.assets}
          active={props.inView}
          preload={config.selectPreloadSpin() || props.preloadIntersection}
          breakpoint={props.useBreakpoint ? config.selectImageBreakpoint() : undefined}
          transformation={props.transformation}
          index={props.index}
          zoom={props.zoom && props.inView && !config.selectSpinPropsDisableZoom()}
          zoomState={props.zoomState}
          setZoom={props.setZoom}
          inPopup={props.inPopup}
          ariaLabel={props.alt}
        />
      );
    } else if (props.mediaType === MediaSymbolTypes.THREE) {
      if (!shouldAssetRender() && !config.selectPreloadThree()) return renderLoading();

      return (
        <Three
          publicId={publicId}
          width={props.width}
          height={props.height}
          active={props.inView ? true : config.selectPreloadThree()}
          transformation={props.transformation}
          ariaLabel={props.alt}
          {...props.context.config.selectAr3dProps()}
        />
      );
    }

    return renderError();
  };

  return (
    <AssetWrapper
      type="button"
      className="assetWrapper"
      tabIndex={props.focusable ? 0 : -1}
      onClick={handleMouseClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      data-index={props.index}
      data-test={props.index}
      width={props.width}
      height={props.height}
      spacing={props.spacing}
      addSideMargin={addSideMargin}
      innerRef={elm => elm && props.setRef && props.setRef(elm, props.index)}
      aria-label={props.alt || 'Asset wrapper'}
    >
      {renderAsset()}
    </AssetWrapper>
  );
};

export default memo(withContext(Asset));
