import { h } from 'preact';
import { FlexCenterStretched } from '../../utils/emotion';
import LoadingCssOnly from './LoadingCssOnly';
import { LoaderStyle } from '../../typing/enums';
import {
  LoadingSvg,
  LoadingWrapper,
  circleLoaderClassName,
  cldLoaderClassName
} from './Loading.styled';

const LOADING_DEFAULTS = {
  height: 4,
  color: '#000',
  opacity: 0.5,
  style: LoaderStyle.CLOUDINARY
};
const QUARTER_OF_IMAGE_WIDTH = 0.25;
const Loading = propsIn => {
  const props = { ...LOADING_DEFAULTS, ...propsIn };
  const width = props.size || (props.width ? props.width * QUARTER_OF_IMAGE_WIDTH : 0);
  const showDefaultLoader =
    props.style === LoaderStyle.CLOUDINARY || (props.style === LoaderStyle.CUSTOM && !props.url);

  return (
    <LoadingWrapper relative className={props.className} data-test="loading">
      <FlexCenterStretched absolute>{props.children}</FlexCenterStretched>

      {showDefaultLoader && (
        <LoadingSvg
          viewBox="-4 -4 151 100"
          preserveAspectRatio="xMidYMid"
          className={cldLoaderClassName(props)}
          width={width}
        >
          <path
            d="M121.663 90.638c-1.796 0-99.33-.498-101.474-1.478C8.685 83.877 1.25 72.196 1.25 59.396c0-16.656 12.797-30.61 29.052-32.323 7.49-15.706 23.186-25.707 40.714-25.707 20.98 0 39.215 14.752 43.945 34.907 15.09.245 27.29 12.63 27.29 27.822 0 11.968-7.738 22.55-19.256 26.33"
            stroke-width="9"
            stroke-linecap="round"
            fill="none"
            fill-rule="evenodd"
            stroke={props.color}
          />
        </LoadingSvg>
      )}
      {props.style === LoaderStyle.CIRCLE && (
        <LoadingSvg
          width={width}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className={circleLoaderClassName(props.opacity)}
        >
          <circle stroke={props.color} cx="50" cy="50" fill="none" r="40" />
        </LoadingSvg>
      )}
      {props.style === LoaderStyle.CUSTOM && props.url && (
        <img width={width} src={props.url} className="custom-loader" alt="" />
      )}
      <LoadingCssOnly
        color={props.color}
        width={width}
        opacity={props.opacity}
        className="css-loader"
      />
    </LoadingWrapper>
  );
};

export default Loading;
