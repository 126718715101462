import { css } from 'react-emotion';

export const baseCss = props =>
  css({
    display: 'inline-block',
    width: !props.size ? '100%' : props.size,
    height: !props.size ? '100%' : props.size,
    cursor: props.onClick ? 'pointer' : null,
    '& path': {
      fill: props.color
    }
  });
