export const MediaAssetKeys = ['tag', 'publicId', 'resourceType', 'mediaType'];

export const ButtonPropsKeys = [
  'shape',
  'iconColor',
  'size',
  'icon',
  'color',
  'disabled',
  'onClick',
  'className',
  'borderColor',
  'borderWidth'
];

export const CarouselPropsKeys = [
  'itemWidth',
  'itemHeight',
  'index',
  'axis',
  'transition',
  'spacing',
  'navigation',
  'children',
  'perView',
  'navigationBorderWidth',
  'navigationBorderColor',
  'navigationColor',
  'navigationIconColor',
  'navigationSize',
  'navigationGutter',
  'navigationShape',
  'navigationFloat',
  'className'
];

export const NavigationButtonPropsKeys = ['shape', 'iconColor', 'color', 'size'];

export const ThumbnailConfigPropsKeys = [
  'transformation',
  'spacing',
  'gutter',
  'perView',
  'width',
  'height',
  'borderWidth',
  'borderColor',
  'radius',
  'navigationBorderColor',
  'navigationBorderWidth',
  'navigationColor',
  'navigationIconColor',
  'navigationSize',
  'navigationShape',
  'navigationFloat',
  'mediaSymbolPosition',
  'mediaSymbolType',
  'MediaSymbolShape',
  'mediaSymbolBgColor',
  'mediaSymbolBgOpacity',
  'mediaSymbolShadow',
  'mediaSymbolColor',
  'mediaSymbolSize',
  'selectedStyle',
  'selectedBorderPosition',
  'selectedBorderColor',
  'selectedBorderWidth',
  'selectedBorderOpacity',
  'selectedGradientStart',
  'selectedGradientEnd',
  'selectedGradientDirection',
  'selectedGradientOpacity',
  'sticky'
];

export const SelectedItemPropsKeys = [
  'style',
  'borderPosition',
  'borderColor',
  'borderWidth',
  'borderOpacity',
  'gradientStart',
  'gradientEnd',
  'gradientOpacity',
  'gradientDirection'
];

export const MediaSymbolPropsKeys = [
  'type',
  'shape',
  'icon',
  'color',
  'opacity',
  'iconShadow',
  'iconColor',
  'size',
  'className'
];

export const CloudinaryConfigKeys = [
  'cloudName',
  'privateCdn',
  'secureDistribution',
  'cname',
  'cdnSubdomain',
  'secure'
];

export const ConfigPropsKeys = [
  'debug',
  'logErrors',
  'container',
  'focus',
  'sort',
  'aspectRatio',
  'analytics',
  'placeholderImage',
  'preload',
  'transition',
  'preventPageScroll',
  'viewportBreakpoints',
  'imageBreakpoint',
  'videoBreakpoint',
  'startIndex',
  'selectedIndex',
  'mediaAssets',
  'transformation',
  'thumbnailContainer',
  'carouselStyle',
  'carouselLocation',
  'carouselOffset',
  'carouselLoop',
  'radius',
  'borderWidth',
  'borderColor',
  'thumbnailProps',
  'indicatorProps',
  'loaderProps',
  'navigation',
  'navigationPosition',
  'navigationOffset',
  'navigationButtonProps',
  'bgColor',
  'zoom',
  'zoomProps',
  'spinProps',
  'ar3dProps',
  'tipProps',
  'zoomPopupProps',
  'videoProps',
  'themeProps',
  'skin',
  'displayProps',
  'queryParam'
];

export const TipPropsKeys = ['textColor', 'color', 'radius', 'opacity'];

export const ZoomPopupPropsKeys = [
  'buttonShape',
  'buttonColor',
  'buttonIconColor',
  'buttonSize',
  'zIndex',
  'backdropColor',
  'backdropOpacity',
  'level',
  'steps',
  'stepLimit',
  'initialZoom'
];

export const SpinPropsKeys = [
  'animate',
  'spinDirection',
  'disableZoom',
  'tipText',
  'tipTouchText',
  'tipPosition',
  'showTip'
];

export const LoaderConfigKeys = ['color', 'size', 'opacity', 'style', 'url'];

export const Ar3dPropsKeys = ['shadows', 'showAR'];

export const VideoConfigKeys = ['autoplay', 'loop', 'controls', 'sound', 'version', 'playerType'];

export const ThemeConfigKeys = ['primary', 'onPrimary', 'active', 'onActive'];

export const IndicatorPropsKeys = [
  'selectedColor',
  'color',
  'size',
  'total',
  'spacing',
  'shape',
  'sticky'
];

export const ZoomConfigKeys = [
  'type',
  'level',
  'steps',
  'stepLimit',
  'viewerPosition',
  'viewerZIndex',
  'viewerOffset',
  'viewerRadius',
  'showLens',
  'lensBorderColor',
  'lensBorderWidth',
  'lensColor',
  'lensRadius',
  'lensOpacity',
  'lensShadow',
  'trigger',
  'container',
  'showTip',
  'tipText',
  'tipTouchText',
  'tipPosition'
];

export const BreakpointPropsKeys = [
  'aspectRatio',
  'transformation',
  'carouselLocation',
  'carouselStyle',
  'carouselOffset',
  'thumbnailProps',
  'indicatorProps',
  'navigation',
  'navigationPosition',
  'navigationOffset',
  'navigationButtonProps',
  'bgColor',
  'zoomProps',
  'radius',
  'borderWidth',
  'borderColor',
  'displayProps',
  'transition'
];

export const ViewportBreakpointConfigPropsKeys = ['breakpoint'];

export const DisplayPropsKeys = ['mode', 'spacing', 'columns', 'topOffset', 'bottomOffset'];
