import { MediaAltSource } from '../typing/enums';
import { isNumber } from './number';
import { getAssetContextualValue, getAssetMetadataValue, getAssetSource } from './assetHelpers';
import { isNil } from './object';

export const getAltText = (config, sourceItem, sourceIndex, total) => {
  if (!isNil(sourceItem.altText)) {
    return sourceItem.altText;
  }
  const accessibilityProps = config.selectConfig().accessibilityProps || {};
  const source = getAssetSource(sourceItem);
  switch (accessibilityProps.mediaAltSource) {
    case MediaAltSource.CONTEXTUAL:
      return getAssetContextualValue(source, accessibilityProps.mediaAltId);
    case MediaAltSource.METADATA:
      return getAssetMetadataValue(source, accessibilityProps.mediaAltId);
    default:
      return isNumber(total) && isNumber(sourceIndex)
        ? `Gallery asset ${sourceIndex + 1} of ${total}`
        : undefined;
  }
};
