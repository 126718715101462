import keys from '../utils/keys';
import { mapKeysToProps } from '../common/props';
import debounce from './debounce';

const prepareMediaQueryList = list => {
  let data = list.map((config, index) => {
    if (index === 0) {
      return {
        mq: `(max-width: ${Number(config.breakpoint)}px)`,
        config: mapKeysToProps(keys('BreakpointProps'), config) || {}
      };
    } else if (index > 0 && index < list.length) {
      return {
        mq: `(min-width: ${Number(list[index - 1].breakpoint) + 1}px) and (max-width: ${
          config.breakpoint
        }px)`,
        config: mapKeysToProps(keys('BreakpointProps'), config) || {}
      };
    }

    return {};
  });
  if (list.length > 0) {
    data = [
      ...data,
      {
        mq: `(min-width: ${Number(list[list.length - 1].breakpoint) + 1}px)`,
        config: {}
      }
    ];
  }

  return data;
};

class mediaQuery {
  constructor(config, matchHandler) {
    this.mediaQueries = [];
    this.mediaQueryListener = [];
    this.onResizeListener = debounce(() => {
      if (this.activeMatchHandler) {
        this.activeMatchHandler();
        this.activeMatchHandler = null;
      }
    }, 250);
    this.config = [...config].sort((a, b) => Number(a.breakpoint) - Number(b.breakpoint));
    this.list = prepareMediaQueryList(this.config);
    this.matchHandler = matchHandler;
    this.list.forEach((breakpoint, index) => {
      this.mediaQueries.push(window.matchMedia(breakpoint.mq || ''));
      if (this.mediaQueries[index].matches) {
        this.matchHandler(breakpoint.config);
      }
      const handler = changed => {
        if (changed.matches) {
          this.activeMatchHandler = () => {
            this.matchHandler(breakpoint.config);
          };
        }
      };
      this.mediaQueryListener.push(handler);
      this.mediaQueries[index].addListener(this.mediaQueryListener[index]);
    });
    // because resize and matchMedia works the same, only change widget when resize is over
    window.addEventListener('resize', this.onResizeListener);
  }

  destroy() {
    this.mediaQueries.forEach((mq, index) => {
      mq.removeListener(this.mediaQueryListener[index]);
    });
    window.removeEventListener('resize', this.onResizeListener);
  }
}

export default mediaQuery;
