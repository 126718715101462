import styled from 'react-emotion';
import { Axis, DisplayMode, IndicatorShape } from '../../typing/enums';

const isScroll = props => props.amount > (props.max || props.amount);

export const getSelectedSize = size => Math.round(size * 1.4);

export const getItemSize = props => props.size + props.spacing * 2;
const getSelectedItemSize = props => getSelectedSize(props.size) + props.spacing * 2;

export const getItemsTotalSize = props =>
  getItemSize(props) * (props.amount - 1) + getSelectedItemSize(props);
const getItemsVisibleSize = props =>
  getItemSize(props) * (props.max || props.amount - 1) + getSelectedItemSize(props);
const getPosition = props => {
  let position = 0;
  if (props.mode === DisplayMode.CLASSIC) {
    const i = props.selected;
    const total = props.amount;
    const max = props.max || props.amount;
    const startIndex = Math.floor(max / 2);
    const endIndex = total - startIndex;
    position =
      i > 0 && i > startIndex && total > max
        ? i >= endIndex
          ? -getItemSize(props) * (endIndex - startIndex - 1)
          : -getItemSize(props) * (i - startIndex)
        : 0;
  }

  return position;
};

export const IndicatorWrap = styled('div')`
  display: flex;
  padding: 0;
  ${props =>
    props.axis === Axis.VERTICAL
      ? `width: ${getSelectedItemSize(props)}px; 
         height: ${
           props.mode === DisplayMode.CLASSIC ? `${getItemsVisibleSize(props)}px` : 'auto'
         };`
      : `height: ${getSelectedSize(props.size)}px; 
         width: ${getItemsVisibleSize(props)}px;`};
  overflow: ${props => (props.mode === DisplayMode.CLASSIC ? 'hidden' : 'visible')};
  ${props => (!isScroll(props) ? 'align-items: center; justify-content: center;' : '')}
`;

export const IndicatorInnerWrap = styled('div')`
  display: flex;
  ${props => (props.axis === Axis.VERTICAL ? 'flex-direction: column' : '')};
  align-items: center;
  justify-content: center;
  transform: translate(
    ${props => (props.axis === Axis.VERTICAL ? '0,' : '')} ${props => getPosition(props)}px
  );
  ${props => (props.axis === Axis.VERTICAL ? 'height' : 'width')}: ${props =>
    getItemsTotalSize(props)}px;
  transition: all 250ms;
`;

export const IndicatorItem = styled('div')`
  ${props =>
    props.shape === IndicatorShape.ROUND
      ? 'border-radius: 100%'
      : props.shape === IndicatorShape.SQUARE
        ? ''
        : props.shape === IndicatorShape.RADIUS
          ? `border-radius:${props.size / 5}px`
          : ''};

  height: ${props => props.size}px;
  width: ${props => props.size}px;
  margin: ${props => props.spacing}px;
  ${props =>
    props.selected
      ? `background-color: ${props.selectedColor}`
      : `background-color: ${props.color}`};
  ${props => (props.selected ? '' : 'cursor: pointer')};
  transition:
    background-color 0.25s ease-in,
    width 0.15s,
    height 0.15s;
`;
