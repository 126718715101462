import { h } from 'preact';
import { cx } from 'react-emotion';
import { baseCss } from './SvgIcon.styled';

const SvgIcon = props => {
  const path = props.path instanceof Array ? props.path : [props.path];

  return (
    <svg
      data-test="svg-image"
      className={cx(baseCss(props), props.className)}
      onClick={props.onClick}
      title={props.title}
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid"
    >
      {path.map((d, i) => (
        <path key={i} d={d} />
      ))}
    </svg>
  );
};

export default SvgIcon;
