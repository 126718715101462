import styled from 'react-emotion';

export const CarouselViewerWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > img {
    max-width: 100%;
    width: 100%;
  }

  border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
  border-radius: ${props => props.radius}px;
`;
