export const getElement = selectorOrDom =>
  selectorOrDom instanceof HTMLElement ? selectorOrDom : document.querySelector(selectorOrDom);

export const getWidth = elem => {
  let width = (elem && elem.getBoundingClientRect().width) || 0;
  if (width) {
    const style = window.getComputedStyle(elem);
    const leftPadding = parseFloat(style.getPropertyValue('padding-left'));
    const rightPadding = parseFloat(style.getPropertyValue('padding-right'));
    width = width - leftPadding - rightPadding;
  }

  return width;
};
// export const getHeight = (elem: HTMLElement): number => {
//   return elem ? elem.getBoundingClientRect().height : 0;
// };
