import { Axis, CarouselLocation } from '../../typing/enums';
import styled from 'react-emotion';
import { getSizeByAxisAndDisplayMode } from '../../utils/wrapperHelpers';

const flexDirectionCalculators = {
  [Axis.HORIZONTAL]: carouselLocation =>
    carouselLocation === CarouselLocation.TOP ? 'column-reverse' : 'column',
  [Axis.VERTICAL]: carouselLocation =>
    carouselLocation === CarouselLocation.LEFT ? 'row-reverse' : 'row'
};

export const GalleryWrapper = styled('div')`
  display: flex;
  flex-direction: ${props => flexDirectionCalculators[props.axis](props.carouselLocation)};

  ${getSizeByAxisAndDisplayMode}
  // normalize css
  div {
    box-sizing: border-box;
    user-select: none;
  }

  img {
    vertical-align: top;
    border-style: none;
    max-width: 100%;
    pointer-events: none;
  }

  button {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  button {
    /* 1 */
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
  }

  [hidden] {
    display: none;
  }

  .js-focus-visible & :focus:not(.focus-visible) {
    outline: none;
  }
`;

export const GallerySpacer = styled('div')`
  ${props =>
    props.axis === Axis.HORIZONTAL ? `height: ${props.spacing}px` : `width: ${props.spacing}px`};
  flex-shrink: 0;
`;
