import { isMobile } from 'mobile-device-detect';
import { DisplayMode } from '../../typing/enums';
import styled from 'react-emotion';
import { getSizeByAxisAndDisplayMode } from '../../utils/wrapperHelpers';

const getStickyPosition = ({ mode, sticky, topOffset, bottomOffset, switchPosition = false }) => {
  let stickyPosition = 'top';
  let offsetValue = topOffset;
  let alignPosition = sticky ? 'flex-start' : 'flex-end';
  if (switchPosition) {
    offsetValue = sticky ? bottomOffset : topOffset;
    stickyPosition = sticky ? 'bottom' : 'top';
    alignPosition = sticky ? 'flex-end' : 'flex-start';
  }

  return !isMobile && mode === DisplayMode.EXPANDED && sticky
    ? `position: sticky;
        align-self: ${alignPosition};
        ${stickyPosition}: ${offsetValue}px;`
    : '';
};

export const AssetsNavigatorWrapper = styled('div')`
  display: flex;
  align-items: start;
  justify-content: center;
  transition: 0.5s ease-in-out;

  ${props => getStickyPosition(props)}

  ${getSizeByAxisAndDisplayMode}
`;
