import { Component, h } from 'preact';
import { addOne as stickyFillAdd } from 'stickyfilljs';
import { CarouselStyle, DisplayMode } from '../../typing/enums';
import Thumbnails from '../Thumbnails/Thumbnails';
import Indicator from '../Indicator/Indicator';
import { withContext } from '../../common/context';
import { AssetsNavigatorWrapper } from './AssetsNavigator.styled';

class AssetsNavigator extends Component {
  wrapperRef = null;

  ignoreSelection = false;

  clickedItem = null;

  componentDidMount() {
    if (this.wrapperRef) {
      stickyFillAdd(this.wrapperRef);
    }

    window.addEventListener('scroll', this.handleStickyPosition, { passive: true });
  }

  componentDidUpdate() {
    if (this.props.mode === DisplayMode.EXPANDED) {
      this.handleThumbnailSelectionForScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleStickyPosition);
  }

  handleThumbnailSelectionForScroll = () => {
    if (this.clickedItem !== this.props.index) {
      this.ignoreSelection = false;
    }
  };

  handleStickyPosition = () => {
    if (!this.wrapperRef) {
      return;
    }
    const wrapperHeight = this.wrapperRef.clientHeight;
    const windowHeight = window.innerHeight;
    const topOffset = this.props.topOffset || 0;
    const switchStickyPosition = wrapperHeight > windowHeight - topOffset;
    if (switchStickyPosition !== this.state.switchStickyPosition) {
      this.setState({ switchStickyPosition });
    }
  };

  handleOnClick = index => {
    // Don't ignore user click when in "classic" mode
    // OR when in "expanded" mode and -
    //      Click on the same thumbnail (diff is 0)
    //      Click on the next / prev thumbnail (diff is 1)
    if (this.props.mode === DisplayMode.EXPANDED && Math.abs(this.props.index - index) > 1) {
      this.ignoreSelection = true;
    }
    this.clickedItem = index;
    this.props.selectItem(index);
  };

  render(props) {
    const { config } = props.context;

    return (
      <AssetsNavigatorWrapper
        innerRef={elm => {
          this.wrapperRef = this.props.mode === DisplayMode.EXPANDED ? elm : null;
        }}
        mode={this.props.mode}
        sticky={this.props.sticky}
        topOffset={this.props.topOffset}
        bottomOffset={this.props.bottomOffset}
        axis={props.axis}
        wrapWidth={props.width}
        wrapHeight={props.viewerDims.height}
        switchPosition={this.state.switchStickyPosition}
        data-test="gallery-thumbnail-wrap"
      >
        {config.selectCarouselStyle() === CarouselStyle.THUMBNAILS ? (
          <Thumbnails
            index={props.index}
            selectedIndex={!this.ignoreSelection && this.props.index}
            onItemSelect={this.handleOnClick}
            setZoom={props.setZoom}
          />
        ) : null}

        {config.selectCarouselStyle() === CarouselStyle.INDICATORS ? (
          <Indicator
            wrapWidth={props.width}
            wrapHeight={props.viewerDims.height}
            {...config.selectIndicatorProps()}
            selected={!this.ignoreSelection && this.props.index}
            amount={config.selectMediaAssets().length}
            onItemSelect={this.handleOnClick}
            axis={props.axis}
            mode={this.props.mode}
          />
        ) : null}
      </AssetsNavigatorWrapper>
    );
  }
}

export default withContext(AssetsNavigator);
