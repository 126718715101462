import { h } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { FlexCenterStretched } from '../../utils/emotion';
import Placeholder from '../Placeholder/Placeholder';

export const LazyComponent = importCallback => {
  const Component = lazy(importCallback);

  return function lazyComp(props) {
    return (
      <Suspense
        fallback={
          props.active ? (
            <FlexCenterStretched>
              <Placeholder
                publicId={props.publicId}
                width={props.width}
                height={props.height}
                mediaType={props.mediaType}
                transformation={props.transformation}
                fetchPriority={props.fetchPriority}
              />
            </FlexCenterStretched>
          ) : null
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
};
