import { Component, h } from 'preact';
import { isMobile } from 'mobile-device-detect';
import { isTouch } from '../../utils/touch';
import { withContext } from '../../common/context';
import CarouselViewer from '../CarouselViewer/CarouselViewer';
import ScrollViewer from '../ScrollViewer/ScrollViewer';
import { DisplayMode, Navigation } from '../../typing/enums';
import { StyledViewerWrapper } from './ViewerWrapper.styled';

class ViewerWrapper extends Component {
  state = {
    scrollIndex: null
  };

  lastScrolledIndex = null;

  componentDidUpdate(prevProps) {
    const index = this.props.index;
    // Set scrollTo for EXPANDED mode in desktop only
    if (
      prevProps.index !== undefined &&
      prevProps.index !== index &&
      index !== this.lastScrolledIndex &&
      this.props.mode === DisplayMode.EXPANDED &&
      !isMobile
    ) {
      this.setScrollIndex(index);
    }
  }

  setScrollIndex = index => {
    this.setState({ scrollIndex: index });
  };

  setScrollItem = index => {
    this.lastScrolledIndex = index;
    this.props.setItem(index);
    if (this.state.scrollIndex !== null) {
      this.setState({ scrollIndex: null });
    }
  };

  render(props) {
    const { config } = props.context;
    let navigation = config.selectNavigation();
    if (isTouch && navigation === Navigation.MOUSE_OVER) {
      navigation = Navigation.ALWAYS;
    }
    const borderWidth = config.selectBorderWidth();
    const width = props.viewerDims.width - borderWidth * 2;
    const height = props.viewerDims.height - borderWidth * 2;

    return (
      <StyledViewerWrapper
        axis={props.axis}
        navigation={navigation}
        className="viewer-wrap"
        data-test="gallery-viewer-wrap"
      >
        {isMobile || props.mode === DisplayMode.CLASSIC ? (
          <CarouselViewer
            index={props.index}
            items={props.items}
            width={width}
            height={height}
            onItemSwipe={props.onItemSwipe}
            navigation={navigation}
            prevItem={props.prevItem}
            nextItem={props.nextItem}
            zoomState={this.props.zoomState}
            setZoom={this.props.setZoom}
          />
        ) : (
          <ScrollViewer
            index={props.index}
            scrollToIndex={this.state.scrollIndex}
            items={props.items}
            width={width}
            height={height}
            setItem={this.setScrollItem}
            zoomState={this.props.zoomState}
            setZoom={this.props.setZoom}
          />
        )}
      </StyledViewerWrapper>
    );
  }
}

export default withContext(ViewerWrapper);
