import styled from 'react-emotion';
import { MediaSymbolPosition } from '../../typing/enums';

export const ThumbnailsWrap = styled('div')`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: start;
  overflow: hidden;

  & .assetWrapper {
    border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
    border-radius: ${props => props.radius}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    overflow: hidden;
  }
`;

export const $SelectedWrap = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;
const getPosition = (position, noShape) => {
  const pos = position.split('-');
  let res = 'top: 0; left: 0';
  if (noShape && position !== MediaSymbolPosition.CENTER) {
    res = `${pos[0]}: 5px; ${pos[1]}: 5px`;
  }

  return res;
};

export const MediaSymbolWrapper = styled('div')`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${props =>
    !props.noShape || props.position === MediaSymbolPosition.CENTER
      ? 'width: 100%; height: 100%;'
      : ''};
  ${props => getPosition(props.position, props.noShape)};
  transform: ${props => props.isClockWise && 'scaleX(-1)'};
`;
