import { h } from 'preact';
import { css, cx } from 'react-emotion';

const stretchedCls = css({
  width: '100%',
  height: '100%'
});

const flexCenterCls = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const imageCentered = css({
  alignSelf: 'center',
  justifySelf: 'center',
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto'
});

export const FlexCenterStretched = propsIn => {
  const props = {
    ...propsIn,
    ...(propsIn.innerRef ? { ref: elem => props.innerRef(elem) } : {})
  };

  return (
    <div
      {...props}
      className={cx(
        stretchedCls,
        flexCenterCls,
        props.className,
        css({
          display: props.hide ? 'none' : 'flex',
          ...(props.relative ? { position: 'relative' } : {}),
          ...(props.absolute ? { position: 'absolute', top: 0, left: 0 } : {})
        })
      )}
    >
      {props.children}
    </div>
  );
};
